import 'powtoon-commons/types'

type fn<T> = () => T
const ACTIONS = {
  VERSION: 'VERSION',
  OPEN_CTA: 'OPEN_CTA',
  RELOAD_PERMISSIONS: 'RELOAD_PERMISSIONS',
  GET_UPLOAD_PROGRESS: 'GET_UPLOAD_PROGRESS'
}
interface UploadStatus {
  status: string,
  uploaded_bytes: number,
  total_bytes: number
}
type MessageCallBack = (response: any) => void
export class CaptureExtensionUtils {
  chromeStoreLink: string = ''
  extensionId: string = ''

  constructor(extensionId: string, chromeStoreLink: string) {
    if (!extensionId) { throw new Error('Missing ExtensionId') }
    this.extensionId = extensionId
    this.chromeStoreLink = chromeStoreLink
    this.addIsAvailableEventToTagManager()
  }

  addIsAvailableEventToTagManager = async () => {
    const isExisting = await this.isExtensionAvailable()
    window.dataLayer?.push({
      capture_available: isExisting ? 'True' : 'False'
    })
  }

  /**
   * on FF we will not have window.chrome object at all
   * on edge we will have the chrome object, but not runtime
   */
  dispatchExtensionAction = (payload: any, callback?: MessageCallBack) => {
    if (window.chrome && chrome.runtime && this.extensionId) {
      chrome.runtime.sendMessage(this.extensionId, payload, callback)
    }
  }

  isExtensionAvailable: fn<Promise<boolean>> = () => new Promise((resolve) => {
    if (!window.chrome) { resolve(false) }

    try {
      if (!(window.chrome && chrome.runtime)) { resolve(false) }
      this.dispatchExtensionAction({action: ACTIONS.VERSION}, (response: any) => {
        if (!chrome.runtime.lastError && response) {
          resolve(true)
        } else {
          resolve(false)
        }
      })
    } catch (e) {
      resolve(false)
    }
  })

  openCTAButton: fn<void> = () => this.dispatchExtensionAction({action: ACTIONS.OPEN_CTA})

  openChromeExtensionsStore: fn<void> = () => window.open(this.chromeStoreLink)

  onCTAClickHandler: fn<void> = async () => {
    const available = await this.isExtensionAvailable()
    if (available) {
      this.openCTAButton()
    }
    else {
      this.openChromeExtensionsStore()
    }
  }

  reloadPermissions: fn<void> = async () => {
    const available = await this.isExtensionAvailable()
    if (available) {
      this.dispatchExtensionAction({action: ACTIONS.RELOAD_PERMISSIONS})
    }
  }

  getUploadProgress: (powtoonId: string) => Promise<number|undefined> = (powtoonId: string) =>
    new Promise((resolve) => {
      this.dispatchExtensionAction({action: ACTIONS.GET_UPLOAD_PROGRESS, powtoonId}, (response: UploadStatus) => {
        if (!chrome.runtime.lastError && response) {
          if (response.status === 'unknown_powtoon') {
            resolve(undefined)
          }
          else {
            if (!response.total_bytes) {
              throw new Error(`Zero 'total_bytes' in response - ${JSON.stringify(response)}`)
            }
            resolve(Math.floor((response.uploaded_bytes / response.total_bytes) * 100))
          }
        } else {
          resolve(undefined)
        }
      })
    })
}
